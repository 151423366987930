// // OWL CALENDAR
.owl-dt-calendar-table .owl-dt-calendar-cell-selected {
	background-color: var(--ion-color-dark);
}

.owl-dt-inline-container,
.owl-dt-popup-container {
	box-shadow: rgb(0 0 0 / 20%) 0px 6px 20px !important;
	border-radius: 16px !important;
}

.owl-dt-calendar-table .owl-dt-calendar-cell-in-range {
	background: rgb(34 36 40 / 8%);
}

.owl-dt-container-info .owl-dt-container-info-active {
	color: var(--ion-color-primary);
}

.owl-dt-calendar-table .owl-dt-calendar-cell-content {
	font-size: 14px !important;
	line-height: 18px !important;
	font-weight: 600 !important;
}

.owl-dt-calendar-table .owl-dt-calendar-header .owl-dt-weekdays th {
	span {
		font-size: 14px;
	}
}

.owl-dt-container {
	font-size: 1.3em;
}

.owl-dt-inline-container .owl-dt-calendar,
.owl-dt-popup-container .owl-dt-calendar {
	height: 27.5em;
	border: none;
}

.owl-dt-container-info .owl-dt-container-range .owl-dt-container-range-content {
	padding: 10px;
}

.owl-dt-container-info {
	padding: 0;
}

.owl-dt-calendar-control {
	padding: 24px 16px;
}

.owl-dt-container-buttons {
	height: 3em !important;
	color: var(--ion-color-primary) !important;
}

.owl-dt-container-info {
	display: none !important;
}

.owl-dt-calendar-cell-disabled {
	.owl-dt-calendar-cell-content {
		text-decoration: line-through;
		color: rgb(176, 176, 176) !important;
	}
}

.owl-dt-calendar-table .owl-dt-calendar-cell-out {
	color: var(--ion-color-medium) !important;
	text-decoration: none !important;
	opacity: 0.4;
}

.owl-dt-control-period-button .owl-dt-control-button-arrow {
	display: none;
}

.owl-dt-control .owl-dt-control-content {
	font-weight: 600 !important;
}

// // TABLES

.dx-diagram {
	font-size: 15px !important;
	font-weight: 600 !important;
}

.dx-diagram-floating-toolbar-container {
	border: none !important;
	background: transparent !important;
	box-shadow: none !important;
}

.dx-diagram-floating-toolbar-container .dx-toolbar-item {
	padding: 0 !important;
}

.dx-toolbar-text-auto-hide .dx-button .dx-icon {
	width: 20px;
	height: 20px;
	background-size: 20px 20px;
	font-size: 20px;
	line-height: 20px;
}

.dx-diagram-floating-toolbar-container .dx-diagram-toolbar .dx-toolbar-items-container>* {
	background: white;
	border-radius: 6px;
	box-shadow: 0 0 20px 0 rgb(213 215 225 / 70%);
}

.dx-button {
	border-radius: 0;
	border-width: 0;
	padding: 0 7px;
}

.dx-diagram {
	border-width: 0;
}

.up-arrow {
	color: var(--ion-color-success);
	font-size: 25px;
	vertical-align: -5px;
}

.dx-context-menu .dx-submenu {
	display: none !important;
}

.dxdi-main {
	.shape {
		&.busy {
			image {
				// filter: invert(4%) sepia(112%) saturate(100000%) hue-rotate(354deg) brightness(110%) contrast(100%);
				// -webkit-filter: invert(4%) sepia(112%) saturate(100000%) hue-rotate(354deg) brightness(110%) contrast(100%);
			}
		}
	}
}

// KUN TOOLTIP

.kun-tooltip {
	background: var(--ion-color-dark) !important;
	box-shadow: none !important;
	border-radius: 8px !important;
	font-size: 14px !important;
	border: none !important;
	width: auto !important;
}

.kun-tooltip__arrow {
	&::after {
		background-color: var(--ion-color-dark) !important;
		box-shadow: none !important;
		border-color: var(--ion-color-dark) !important;
	}
}

swiper-container {
	&::part(button-prev) {
		background: white;
		width: 15px;
		height: 15px;
		padding: 8px;
		border-radius: 100px;
		box-shadow: 0 4px 16px rgba(0, 0, 0, 0.12);
	}

	&::part(button-next) {
		background: white;
		width: 15px;
		height: 15px;
		padding: 8px;
		border-radius: 100px;
		box-shadow: 0 4px 16px rgba(0, 0, 0, 0.12);
	}
}