ion-icon {
	&[class*="icon-"] {
		mask-size: contain;
		mask-repeat: no-repeat;
		background: currentColor;
	}

	&[class*="icon-bluetooth"] {
		mask-image: url(../assets/icon/bluetooth.svg);
	}

	&[class*="icon-card"] {
		mask-image: url(../assets/icon/card.svg);
	}

	&[class*="icon-dollar"] {
		mask-image: url(../assets/icon/dollar.svg);
	}

	&[class*="icon-ticket"] {
		mask-image: url(../assets/icon/ticket.svg);
	}

	&[class*="icon-tables"] {
		mask-image: url(../assets/icon/tables.svg);
	}

	&[class*="icon-storage"] {
		mask-image: url(../assets/icon/storage.svg);
	}
	&[class*="icon-credit-card"] {
		mask-image: url(../assets/icon/credit-card.png);
	}

	&[class*="icon-dte"] {
		mask-image: url(../assets/icon/desktop-arrow-down.svg);
	}

	&[class*="icon-promotions"] {
		mask-image: url(../assets/icon/promotions.svg);
	}

	&[class*="icon-eye"] {
		mask-image: url(../assets/icon/eye.png);
	}

	&[class*="icon-other"] {
		mask-image: url(../assets/icon/other.svg);
	}

	&[class*="icon-location"] {
		mask-image: url(../assets/icon/location.svg);
	}

	&[class*="icon-delivery"] {
		mask-image: url(../assets/icon/delivery.svg);
	}

	&[class*="icon-bookmark"] {
		mask-image: url(../assets/icon/bookmark.svg);
	}

	&[class*="icon-separate"] {
		mask-image: url(../assets/icon/separate.svg);
	}

	&[class*="icon-divide"] {
		mask-image: url(../assets/icon/divide.svg);
	}

	&[class*="icon-order"] {
		mask-image: url(../assets/icon/order.svg);
	}

	&[class*="icon-voucher-type"] {
		mask-image: url(../assets/icon/voucher-type.png);
	}

	&[class*="icon-pending-payment"] {
		mask-image: url(../assets/icon/pending-payment.png);
	}

	&[class*="icon-wallet"] {
		mask-image: url(../assets/icon/wallet.svg);
	}

	&[class*="icon-link"] {
		mask-image: url(../assets/icon/link.svg);
	}

	&[class*="icon-pos"] {
		mask-image: url(../assets/icon/pos.png);
	}

	&[class*="icon-clients"] {
		mask-image: url(../assets/icon/clients.svg);
	}

	&[class*="icon-carry"] {
		mask-image: url(../assets/icon/carry.svg);
	}

	&[class*="icon-delivery"] {
		mask-image: url(../assets/icon/delivery.svg);
	}

	&[class*="icon-gift"] {
		mask-image: url(../assets/icon/gift.svg);
	}

	&[class*="icon-graph"] {
		mask-image: url(../assets/icon/graph.svg);
	}

	&[class*="icon-cancel"] {
		mask-image: url(../assets/icon/cancel.svg);
	}

	&[class*="icon-close"] {
		mask-image: url(../assets/icon/close.png);
	}

	&[class*="icon-printer"] {
		mask-image: url(../assets/icon/printer.svg);
	}

	&[class*="icon-transaction"] {
		mask-image: url(../assets/icon/transaction.svg);
	}

	&[class*="icon-minus"] {
		mask-image: url(../assets/icon/minus.png);
	}

	&[class*="icon-add"] {
		mask-image: url(../assets/icon/add.png);
	}

	&[class*="icon-permission"] {
		mask-image: url(../assets/icon/permission.svg);
	}

	&[class*="icon-fisc"] {
		mask-image: url(../assets/icon/fisc.svg);
	}

	&[class*="icon-category"] {
		mask-image: url(../assets/icon/category.svg);
	}

	&[class*="icon-inventory"] {
		mask-image: url(../assets/icon/inventory.svg);
	}

	&[class*="icon-income"] {
		mask-image: url(../assets/icon/income.svg);
	}

	&[class*="icon-refund"] {
		mask-image: url(../assets/icon/refund.svg);
	}

	&[class*="icon-return"] {
		mask-image: url(../assets/icon/return.svg);
	}

	&[class*="icon-discount"] {
		mask-image: url(../assets/icon/discounts.svg);
	}

	&[class*="icon-wait"] {
		mask-image: url(../assets/icon/wait.svg);
	}

	&[class*="icon-note"] {
		mask-image: url(../assets/icon/note.svg);
	}

	&[class*="icon-trash"] {
		mask-image: url(../assets/icon/trash.svg);
	}

	&[class*="icon-price"] {
		mask-image: url(../assets/icon/price.svg);
	}

	&[class*="icon-edit"] {
		mask-image: url(../assets/icon/edit.svg);
	}

	&[class*="icon-mail"] {
		mask-image: url(../assets/icon/mail.svg);
	}

	&[class*="icon-printer"] {
		mask-image: url(../assets/icon/printer.svg);
	}

	&[class*="icon-home"] {
		mask-image: url(../assets/icon/home.svg);
	}

	&[class*="icon-products"] {
		mask-image: url(../assets/icon/products.png);
	}

	&[class*="icon-kds"] {
		mask-image: url(../assets/icon/kds.svg);
	}

	&[class*="icon-tax"] {
		mask-image: url(../assets/icon/tax.svg);
	}

	&[class*="icon-pos-delivery"] {
		mask-image: url(../assets/icon/pos/delivery.svg);
	}

	&[class*="icon-pos-giftcard"] {
		mask-image: url(../assets/icon/pos/giftcard.svg);
	}

	&[class*="icon-pos-tables"] {
		mask-image: url(../assets/icon/pos/tables.svg);
	}
}