:root {
	--ion-color-primary: #236bfe;
	--ion-color-primary-rgb: 35, 107, 254;
	--ion-color-primary-contrast: #ffffff;
	--ion-color-primary-contrast-rgb: 255, 255, 255;
	--ion-color-primary-shade: #1f5ee0;
	--ion-color-primary-tint: #397afe;

	--ion-color-secondary: #239ffe;
	--ion-color-secondary-rgb: 35, 159, 254;
	--ion-color-secondary-contrast: #000000;
	--ion-color-secondary-contrast-rgb: 0, 0, 0;
	--ion-color-secondary-shade: #1f8ce0;
	--ion-color-secondary-tint: #39a9fe;

	--ion-color-tertiary: #95f7de;
	--ion-color-tertiary-rgb: 149, 247, 222;
	--ion-color-tertiary-contrast: #000000;
	--ion-color-tertiary-contrast-rgb: 0, 0, 0;
	--ion-color-tertiary-shade: #83d9c3;
	--ion-color-tertiary-tint: #a0f8e1;

	--ion-color-success: #038060;
	--ion-color-success-rgb: 3, 128, 96;
	--ion-color-success-contrast: #ffffff;
	--ion-color-success-contrast-rgb: 255, 255, 255;
	--ion-color-success-shade: #037154;
	--ion-color-success-tint: #1c8d70;

	--ion-color-warning: #FFDD33;
	--ion-color-warning-rgb: 255, 221, 51;
	--ion-color-warning-contrast: #000000;
	--ion-color-warning-contrast-rgb: 0, 0, 0;
	--ion-color-warning-shade: #e0c22d;
	--ion-color-warning-tint: #ffe047;

	--ion-color-danger: #ff4759;
	--ion-color-danger-rgb: 255, 71, 89;
	--ion-color-danger-contrast: #fff;
	--ion-color-danger-contrast-rgb: 0, 0, 0;
	--ion-color-danger-shade: #e03e4e;
	--ion-color-danger-tint: #ff596a;

	--ion-color-medium: #7f828a;
	--ion-color-medium-rgb: 127, 130, 138;
	--ion-color-medium-contrast: #000000;
	--ion-color-medium-contrast-rgb: 0, 0, 0;
	--ion-color-medium-shade: #707279;
	--ion-color-medium-tint: #8c8f96;

	--ion-color-light: #f3f6fa;
	--ion-color-light-rgb: 243, 246, 250;
	--ion-color-light-contrast: #000000;
	--ion-color-light-contrast-rgb: 0, 0, 0;
	--ion-color-light-shade: #d6d8dc;
	--ion-color-light-tint: #f4f7fb;

	--ion-color-dark: #161b26;
	--ion-color-dark-rgb: 22, 27, 38;
	--ion-color-dark-contrast: #ffffff;
	--ion-color-dark-contrast-rgb: 255, 255, 255;
	--ion-color-dark-shade: #131821;
	--ion-color-dark-tint: #2d323c;
}