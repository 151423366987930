@media (max-width: 767px) {

	.app {
		&--hide {
			display: none !important;
		}

		&--show {
			display: block !important;
		}
	}

	.type-modal {
		--width: 1000px;
		--height: 100% !important;
	}


	ion-alert {
		--min-width: calc(100% - 32px) !important;
	}

	.menu-table {
		position: sticky;
		top: -16px;
		z-index: 111111;
		background: white !important;
		padding: 0 !important;

		ion-searchbar {
			--border-radius: 0 !important;
			height: 50px !important;
		}
	}

	.table-header {
		display: none;
	}

	ion-label {
		font-size: 15px !important;
	}

	ion-popover ion-label {
		font-size: 15px !important;
	}

	ion-fab {
		margin-left: 8px;
		margin-right: 8px;
		margin-bottom: env(safe-area-inset-bottom) !important;

		ion-fab-button {
			--background: linear-gradient(56deg,
					rgba(35, 107, 254, 1) 35%,
					rgba(35, 159, 254, 1) 100%);
			--background-hover: linear-gradient(56deg,
					rgba(35, 107, 254, 1) 35%,
					rgba(35, 159, 254, 1) 100%);
			--background-activated: linear-gradient(56deg,
					rgba(35, 107, 254, 1) 35%,
					rgba(35, 159, 254, 1) 100%);
		}

		ion-icon {
			font-size: 20px;
		}
	}

	.card-content-ios p,
	ion-text {
		font-size: 15px !important;
	}

	.table-container {
		margin: 0;
		margin-bottom: 100px !important;
		border-radius: 0 !important;
		box-shadow: none !important;

		ion-card-header {
			padding: 8px 16px !important;
			gap: 8px !important;
			position: sticky;
			background: white;
			top: 0;
			z-index: 1111111;

			.button-has-icon-only {
				--padding-start: 0;
				--padding-end: 0;
				width: 40px !important;
				min-width: 40px !important;
				max-width: 40px !important;

				ion-icon {
					font-size: 18px !important;
				}
			}

			ion-searchbar {
				width: 100% !important;
				// height: 40px !important;
			}

			ion-segment {
				margin: 0 !important;
			}
		}

		.header {
			display: none;
		}

		.body {
			ion-col {
				height: unset !important;
				max-height: unset !important;
			}
		}
	}

	.old-table-container {
		margin: -16px !important;
		border-radius: 0 !important;
		box-shadow: none !important;
	}

	.title {
		margin-bottom: 25px;

		h1 {
			font-weight: 600;
			color: #181c32;
			font-size: 18px;
		}

		p,
		span {
			color: #a1a5b7;
			font-size: 13px;
			margin-top: 10px;
		}

		ion-badge {
			margin-bottom: 4px;
			background: #e3e8ee;
			color: #4f566b;
			font-size: 12px;
			font-weight: 600;
			margin: 0;
			margin-left: 5px;
		}
	}

	ion-toast {
		--width: auto !important;
	}

	ion-modal {
		--min-width: 100%;
		--min-height: 100%;
		--border-radius: 0 !important;

		ion-header {
			ion-toolbar {
				--padding-top: 0 !important;
			}
		}

		.table-header {
			display: block;
		}

		.old-table-container {
			margin: 0 !important;
		}

		.table-container {
			margin: 0 !important;
		}
	}

	.thread {

		&__horizontal,
		&__vertical {
			left: 0 !important;
		}

		&__name {
			margin-left: 35px !important;
		}
	}

	.empty-data {
		padding: 50px 40px !important;
	}

	ion-popover {
		ion-footer {
			padding-bottom: 16px !important;
		}
	}

	ion-footer {
		padding-bottom: env(safe-area-inset-bottom) !important;

		ion-button {
			font-size: 17px;
		}
	}

	.flex {
		&--responsive {
			display: block !important;
		}
	}

	.quanto-loader {
		.loading-spinner {
			height: 60px;

			ion-spinner {
				width: 60px !important;
				height: 60px;
			}
		}

		.loading-content {
			font-size: 18px;
		}
	}

	ion-content {
		--background: white;
	}

	.inner-content {
		height: 100%;
		padding: 24px;
		overflow: auto;
	}

	ion-segment {
		ion-segment-button {
			font-size: 14px !important;
		}
	}

	ion-input,
	ion-textarea {

		input,
		textarea {
			font-size: 16px !important;
		}
	}

	.numeric-panel {
		padding-bottom: env(safe-area-inset-bottom) !important;
	}

	.sheet-modal {
		--min-height: unset !important;
		--height: auto;
		--border-radius: 13px !important;

		ion-header {
			ion-toolbar {
				padding-bottom: 0 !important;
			}
		}

		.inner-content {
			padding-bottom: env(safe-area-inset-bottom) !important;
			height: fit-content !important;
			padding: 24px;
			overflow: auto;

			.ion-page {
				position: relative;
				display: block;
				contain: content;

				.inner-content {
					max-height: 80vh;
					overflow: auto;
					padding: 24px;
				}
			}
		}
	}
}

@media (min-width: 767px) {

	ion-alert {
		--min-width: 400px !important;
	}

	ion-modal {
		--width: 600px !important;
	}

	ion-modal.auto-height {
		&.bottom {
			align-items: flex-end;
		}

		--height: auto;

		.ion-page {
			position: relative;
			display: block;
			contain: content;

			.inner-content {
				max-height: 80vh;
				overflow: auto;
				padding: 24px;
			}
		}
	}

	ion-modal.two-columns {
		--width: 900px !important;
	}
}