.add-item-modal {
	--width: 700px !important;
	--height: 650px;
}

.ticket-detail-modal {
	--width: 100% !important;
	--height: 100%;
	--border-radius: 0;
}

.closing-modal {
	--width: 100% !important;
	--height: 100%;
	--border-radius: 0;
}

.ticket-options-modal {
	--height: 570px;
}

.clients-modal {
	--height: 90%;
}

.inventory-modal {
	--width: 80% !important;
	--height: 70% !important;
}

.branches-popover {
	--width: 250px !important;

	ion-list {
		padding: 13px;
		padding-bottom: 0;

		ion-item {
			--padding-bottom: 13px;
			--padding-start: 0;
			--padding-end: 13px;

			h6 {
				font-weight: 600;
				font-size: 13px;
			}

			p {
				font-size: 12px;
			}
		}
	}
}

.code-modal {
	z-index: 20008;
	--height: 247px;
	--width: 400px !important;
}

.type-modal {
	--width: 1000px !important;
	--height: 80%;

	&::part(backdrop) {
		--backdrop-opacity: var(--ion-backdrop-opacity, 0.7);
		background: var(--ion-color-dark);
	}
}

.select-popover {
	&::part(backdrop) {
		--backdrop-opacity: var(--ion-backdrop-opacity, 0);
		background: var(--ion-color-dark);
	}
}

.number-modal {
	--width: 350px !important;
}

.coutdown-modal {
	--width: 100% !important;
	--height: 100%;
	--background: transparent;
	--backdrop-opacity: 0.4;
	--border-radius: 0;

	ion-content {
		--background: transparent !important;
	}

	.ion-page {
		margin: 0 !important;
		border: none !important;
	}
}

.edit-item-modal {
	--width: 600px !important;
	--height: 620px;
}

.tickets-modal {
	--height: 80%;
	--width: 750px !important;
}

.ticket-item-modal {
	--height: 500px;

	ion-title {
		padding-left: 0;
	}
}

.pay-board-modal {
	--width: 750px !important;
	--height: 90%;
}

.pay-credit-modal {
	--width: 60.5% !important;
	--height: 100%;
	right: 0;
	justify-content: flex-end;
	--border-radius: 0;
}

.code-modal {
	z-index: 20008;
	--height: 247px;
	--width: 400px !important;
}

.device-modal {
	--height: 560px;
}

.admin-modal {
	--height: 620px;
}

.search-popover::part(content) {
	--min-width: 43.75rem;
	--max-width: 43.75rem;
	top: 32px;
}

.search-popover::part(backdrop) {
	background-color: var(--ion-backdrop-color, #000);
	--backdrop-opacity: var(--ion-backdrop-opacity, 0.1);
}

.select-client-modal {
	--width: 1000px !important;
	--height: calc(100vh - 20vh);
}

.color-picker-modal {
	--width: 230px !important;
}

.category-modal {
	--height: 450px;
}

.supplier-modal {
	--height: 500px;
}

.product-modal {
	--width: 100% !important;
	--height: 100%;
	--border-radius: 0;
}

.menu-header::part(content) {
	--width: 290px !important;
	transform: translateX(-10px);
}

.status-popover {
	--width: 250px !important;

	ion-item-divider {
		font-size: 11px;
		color: var(--ion-color-medium);
		background: white;
		font-weight: 600;
		padding: 10px 16px;
		padding-bottom: 0;
	}

	ion-icon {
		font-size: 13px;
	}
}

.user-modal {
	--width: 850px !important;
	--height: 515px;
}

.filter-modal {
	--width: 300px !important;

	ion-content {
		--background: white;
	}

	ion-item {
		--background-hover: transparent !important;
	}
}

ion-modal {
	.container {
		height: 100%;
	}

	.menu {
		height: fit-content;
		padding: 16px;

		ion-item {
			--padding-start: 8px;
			--padding-end: 8px;
			border-radius: 8px;
			cursor: pointer;

			ion-icon {
				font-size: 30px;
				color: var(--ion-color-dark-tint);
			}

			ion-label {
				font-size: 16px !important;
				color: var(--ion-color-dark);
				font-weight: 600;
				margin: 16px 0 !important;
				padding: 0;
			}
		}
	}
}

.full-modal {
	--width: 100% !important;
	--height: 100%;
	--border-radius: 0;
}

.pay-modal {
	--width: 100% !important;
	--height: 100%;
	--border-radius: 0;
}

.add-product-popover {
	--height: 300px;
}

.branches-popover {
	--width: 250px;

	ion-list {
		padding-top: 0;

		ion-item {
			--border-color: white;
			--padding-start: 7px;
			margin-top: 10px;

			h6 {
				font-weight: 600;
				font-size: 13px;
			}

			p {
				font-size: 12px;
			}
		}
	}

	ion-label {
		padding: 0;
	}
}

.categories-pos-popover {
	--max-height: 50%;
}

.select-popover::part(backdrop) {
	background-color: var(--ion-backdrop-color, #000);
	--backdrop-opacity: var(--ion-backdrop-opacity, 0);
}

.emoji-picker-popover {
	--width: 300px;
	--height: 30%;
}

.menu-popover {
	--height: auto;

	ion-item {
		--padding-top: 2px;
		--padding-bottom: 2px;
	}

	ion-text {
		font-weight: 600;
		font-size: 14px;
	}

	ion-label {
		padding: 0 !important;
		font-weight: 600;
	}
}

.search-popover::part(content) {
	--min-width: 43.75rem;
	--max-width: 43.75rem;
	top: 32px;
}

.search-popover::part(backdrop) {
	background-color: var(--ion-backdrop-color, #000);
	--backdrop-opacity: var(--ion-backdrop-opacity, 0.1);
}

.status-popover {
	--width: 250px;

	ion-item-divider {
		font-size: 11px;
		color: var(--ion-color-medium);
		background: white;
		font-weight: 600;
		padding: 10px 16px;
		padding-bottom: 0;
		text-transform: uppercase;
		padding-top: 0;
		padding-left: 12px;
	}

	ion-icon {
		font-size: 13px;
	}
}

.type-status-popover {
	--height: 130px;

	ion-item-divider {
		font-size: 11px;
		color: var(--ion-color-medium);
		background: white;
		font-weight: 600;
		padding: 0;
		padding-left: 10px;
		padding-bottom: 5px;
	}

	ion-icon {
		font-size: 13px;
	}
}

.filter-popover {
	--width: 300px;

	ion-button {
		font-size: 14px;
	}

	ion-content {
		--padding-start: 10px;
		--padding-end: 10px;
		--padding-bottom: 10px;
		--padding-top: 10px;
	}

	ion-header {
		ion-toolbar {
			padding: 0 8px !important;

			ion-title {
				font-size: 13px;
				color: var(--ion-color-medium-shade);
			}
		}
	}

	ion-footer {
		padding: 4px 12px !important;

		ion-toolbar {
			ion-button {
				--padding-start: 8px !important;
				--padding-end: 8px !important;
			}
		}
	}
}

.select-method-modal {
	--height: 300px !important;
	--min-height: 300px !important;
	--border-radius: 13px !important;
}

.set-inventory-modal {
	--height: calc(305px + env(safe-area-inset-bottom)) !important;
	--min-height: calc(305px + env(safe-area-inset-bottom)) !important;
	--border-radius: 13px !important;
}