// // // // // // // // // //
// JUST IONIC
// // // // // // // // // //

ion-header,
ion-footer {
	ion-toolbar {
		--border-color: #f2f2f2;
		--min-height: 56px;
		--padding-start: 16px;
		--padding-end: 16px;
		--background: white;

		ion-title {
			font-weight: 600;
			margin: auto;
			position: relative;
		}

		.button-has-icon-only {
			height: 36px !important;
			width: 36px !important;
			--background: transparent;
			transition: 250ms;
			min-width: 36px;
			max-width: 36px;
			max-height: 36px;
			min-height: 36px;

			&:hover {
				--background: rgba(22, 22, 26, 0.04);
			}

			ion-icon {
				font-size: 22px !important;
			}
		}
	}
}

ion-header {
	ion-toolbar {
		--border-width: 0 0 1px !important;
	}
}

ion-footer {
	ion-toolbar {
		--border-width: 1px 0 0 !important;
	}
}

ion-reorder {
	&::part(icon) {
		font-size: 25px !important;
	}
}

ion-icon {
	--ionicon-stroke-width: 50px;
}

ion-item-divider {
	background: var(--ion-color-light);
	padding: 8px 16px;

	ion-label {
		padding: 0 !important;
		font-weight: 600;
	}
}

ion-toast {
	font-weight: 600;
	--width: fit-content !important;
	--max-width: calc(100% - 32px) !important;
	--border-radius: 200px;
	--padding-end: 32px !important;
	--margin-bottom: 15px;
	--box-shadow: 0 4px 24px 0 rgba(34, 41, 47, 0.05) !important;
	--background: var(--ion-color-dark);

	&::part(container) {
		padding-right: 6px;
	}

	&::part(message) {
		font-size: 15px;
		color: white !important;
	}

	&::part(icon) {
		font-size: 22px !important;
		margin-left: 15px;
	}

	&.success {
		&::part(icon) {
			color: var(--ion-color-success-tint) !important;
		}
	}

	&.error {
		&::part(icon) {
			color: var(--ion-color-danger-tint) !important;
		}
	}
}

ion-alert {

	.alert-wrapper {
		background: white !important;
	}

	.alert-head {
		padding-inline: 24px !important;
		padding-top: 24px !important;
		padding-bottom: 12px !important;

		.alert-title {
			font-size: 22px !important;
			text-align: left !important;
		}

		.alert-sub-title {
			text-align: left !important;
			font-size: 15px !important;
		}
	}

	.alert-message {
		font-size: 15px !important;
		line-height: 1.5 !important;
		text-align: left !important;
		padding-inline: 24px !important;
		padding-bottom: 24px !important;
	}

	.alert-button-group {
		padding-inline: 24px !important;
		padding-bottom: 24px !important;
		gap: 16px !important;
		flex-wrap: unset !important;
		justify-content: center;

		button {
			border-radius: 8px !important;
			border: none !important;
			background-color: var(--ion-color-primary) !important;
			color: white !important;
			width: 100% !important;
			min-width: unset !important;
			max-width: unset !important;

			span {
				font-size: 16px !important;
				font-weight: 600 !important;
			}
		}

		.alert-button-role-cancel {
			background: var(--ion-color-light) !important;
			color: black !important;
		}

		.alert-button-role-destructive {
			background: var(--ion-color-danger) !important;
			color: white !important;
		}
	}
}

ion-radio-group {
	ion-label {
		padding: 0 !important;
	}
}

ion-accordion {
	background: transparent !important;

	.ion-accordion-toggle-icon {
		display: none !important;
	}

	&.accordion-expanded>[slot="header"] div ion-icon[name="chevron-down-outline"][slot="icon-only"] {
		transition: 250ms;
		transform: rotate(180deg);
	}
}

ion-button {
	margin: 0;
	font-weight: 500 !important;
	--border-radius: 8px;
	--padding-start: 18px;
	--padding-end: 18px;
	text-wrap: nowrap;

	span,
	ion-label {
		font-weight: 500 !important;
	}

	ion-spinner {
		color: white !important;
	}

	&[loading="true"] {
		--color: transparent;
		pointer-events: none !important;
	}

	&.button-disabled {
		--background: var(--ion-color-light-shade);
		--color: var(--ion-color-medium-shade);
		--border-color: var(--ion-color-light-shade);
	}

	&.button-has-icon-only {
		ion-icon {
			margin-right: 0 !important;
		}
	}
}

ion-checkbox {
	--border-color: #dedfe1 !important;
	--border-radius: 6px;
	width: fit-content;
	--size: 22px
}

ion-card {
	box-shadow: none;
	border-radius: 13px;
	margin: 16px;

	ion-card-content {
		padding: 1.5rem !important;
	}
}

ion-modal {
	--border-radius: 13px;

	&::part(handle) {
		display: none !important;
	}

	ion-header {
		ion-toolbar {
			display: flex;
			align-items: center;
			--padding-start: unset;
			--padding-end: unset;

			ion-back-button {
				margin-left: 8px;

				&::part(icon) {
					--ionicon-stroke-width: 80px !important;
				}
			}

			ion-title {
				font-size: 16px;
				padding: 16px;
				z-index: -1;
				position: relative;

				.sub-title {
					font-size: 13px;
					margin-bottom: 3px;
					color: #a3a3a3 !important;
				}

				span {
					font-size: 16px;
				}
			}

			ion-button {
				font-size: 16px !important;
			}

			ion-note {
				margin-right: 10px;
			}
		}
	}

	ion-content {
		--background: white;

		form {
			padding: 5px;
		}
	}

	.show-arrow {
		.ion-accordion-toggle-icon {
			position: relative !important;
			font-size: 16px !important;
		}
	}

	.table-container {
		box-shadow: none !important;
		margin: 0 !important;
		position: relative;
		z-index: 1;
		border-radius: 0;

		.table-header {
			padding-left: 8px !important;
			padding-right: 8px !important;
			top: 0;
		}

		.table-body {
			ion-grid {
				padding-left: 8px !important;
				padding-right: 8px !important;
			}
		}
	}

	ion-footer {
		ion-toolbar {
			padding: 0 !important;
			--min-height: 67px;
		}
	}
}

ion-content {
	--background: var(--ion-color-light);
}

ion-toggle {
	width: auto;
	--handle-width: 22px;
	color: black;

	&::part(track) {
		height: 25px;
		width: 40px;
	}
}

ion-searchbar {
	padding: 0 !important;
	--border-radius: 8px !important;
	height: 45px !important;
	min-height: 45px !important;
	max-height: 45px !important;
	--box-shadow: 0 4px 24px 0 rgba(34, 41, 47, 0.05) !important;

	.searchbar-input-container {
		height: 100% !important;

		.searchbar-search-icon {
			width: 18px !important;
			padding-left: 10px;
			color: var(--ion-color-medium) !important;
		}

		.searchbar-clear-button {
			margin-right: 5px;
		}

		.searchbar-clear-icon {
			color: var(--ion-color-medium-tint) !important;
		}
	}

	input {
		transition: 200ms;
		font-size: 14px !important;
		padding-inline-start: 50px !important;
		border: 2px solid transparent !important;
	}

	&.searchbar-has-focus {
		input {
			background: white !important;
			border-color: var(--ion-color-primary) !important;
			--box-shadow: 0 4px 24px 0 rgba(34, 41, 47, 0.05) !important;
		}
	}
}

ion-label {
	margin: 0;
	padding-bottom: 4px;
	font-size: 14px !important;
	font-weight: 500;
}

textarea {
	overflow: hidden !important;
}

.big-input {
	input {
		font-size: 17px !important;
	}
}

ion-input,
ion-textarea {
	color: black !important;
	--placeholder-opacity: 0.40 !important;
	--border-radius: 8px !important;

	.label-text {
		font-size: 19px !important;
		font-weight: 500 !important;
		color: black;
		pointer-events: auto;

		.tooltip {
			font-size: 20px;
			color: var(--ion-color-medium);
			cursor: pointer;
			margin-bottom: -2px;
		}
	}

	.label-text-wrapper {
		margin-bottom: 12px;
		margin-top: -11px;
	}

	input,
	textarea {
		font-size: 14px;
		border: 1px solid #dedede !important;
		border-radius: 8px !important;
		padding: 13px 16px !important;
		margin-top: 1px !important;
	}

	img {
		width: 25px;
		border-radius: 3px;
		margin-right: 10px;
	}

	ion-button {
		position: absolute;
		right: 0;
		top: 0;
		pointer-events: auto;
		z-index: 11111111111;
		min-height: 45px;
		height: 45px;
		max-height: 45px;

		ion-icon {
			font-size: 18px;
		}
	}

	&.input-label-placement-stacked {
		ion-button {
			margin-top: 27px !important;
		}
	}

	&[required] {
		.label-text:after {
			content: "*";
			position: relative;
			font-size: inherit;
			color: #f1416c;
			font-weight: 600;
			margin-left: 3px;
		}
	}

	.textarea-bottom,
	.input-bottom {
		border: none !important;
		justify-content: flex-start !important;
	}
}

.check-form {

	ion-input,
	ion-select {
		&.ion-invalid {
			input {
				border-color: var(--ion-color-danger) !important;
			}

			.error-text {
				display: block !important;
			}
		}
	}
}

ion-select {
	min-height: fit-content;
	border: 1px solid #dedede;
	margin-top: calc(17px + 0.5rem);
	border-radius: 8px;
	display: inline-table;
	--highlight-height: 0;

	&::part(label) {
		position: absolute;
		top: calc(-20px - 0.5rem);
		font-size: 14px !important;
		font-weight: 500;
		transform: none !important;
	}

	&::part(container) {
		padding: 13px 16px !important;
		font-size: 14px;
		margin: 0;
	}

	&::part(icon) {
		right: 13px;
	}

	&[required] {
		&::part(label):after {
			content: "*";
			position: relative;
			font-size: inherit;
			color: #f1416c;
			font-weight: 600;
		}
	}
}


// ion-select {
// 	--border-radius: 8px;
// 	--border-width: 1px;
// 	--border-color: red;
// 	--border-style: solid;
// 	// &::part(label) {
// 	// 	font-size: 19px !important;
// 	// 	font-weight: 500 !important;
// 	// 	color: black;
// 	// 	pointer-events: auto;
// 	// 	margin-bottom: 12px;
// 	// 	margin-top: -11px;

// 	// 	.tooltip {
// 	// 		font-size: 20px;
// 	// 		color: var(--ion-color-medium);
// 	// 		cursor: pointer;
// 	// 		margin-bottom: -2px;
// 	// 	}
// 	// }

// 	// &::part(container) {
// 	// 	font-size: 14px !important;
// 	// 	border: 1px solid #dedede !important;
// 	// 	color: black !important;
// 	// 	border-radius: 8px !important;
// 	// 	padding: 13px 16px !important;
// 	// }

// 	// &::part(icon) {
// 	// 	height: 46px;
// 	// 	bottom: 0;
// 	// }

// 	// &[required] {
// 	// 	&::part(label):after {
// 	// 		content: "*";
// 	// 		position: relative;
// 	// 		font-size: inherit;
// 	// 		color: #f1416c;
// 	// 		font-weight: 600;
// 	// 	}
// 	// }

// 	// &.select-label-placement-start {
// 	// 	--padding-end: 0;

// 	// 	&::part(container) {
// 	// 		height: 18px;
// 	// 	}
// 	// }
// }


ion-fab {
	margin: 8px;
	margin-bottom: env(safe-area-inset-bottom) !important;

	ion-badge {
		position: absolute;
		top: -4px;
		right: -4px;
		color: var(--ion-color-danger);
	}

	ion-fab-button {
		--background: linear-gradient(56deg,
				rgba(35, 107, 254, 1) 35%,
				rgba(35, 159, 254, 1) 100%);
		--background-hover: linear-gradient(56deg,
				rgba(35, 107, 254, 1) 35%,
				rgba(35, 159, 254, 1) 100%);
		--background-activated: linear-gradient(56deg,
				rgba(35, 107, 254, 1) 35%,
				rgba(35, 159, 254, 1) 100%);
	}

	ion-icon {
		font-size: 20px;
	}
}

ion-input:read-only {
	input:read-only {
		cursor: pointer !important;
	}
}

ion-chip {
	margin: 0;
	font-weight: 600;
	font-size: 12px;
	height: 27px;
	min-height: 27px;
	max-height: 27px;
}

ion-spinner {
	width: 100%;
	--color: var(--ion-color-primary);
	position: absolute;
	top: 0;
	bottom: 0;
	margin: auto;
}

ion-popover {
	--border-radius: 12px;
	--width: 240px;
	--box-shadow: 0 4px 24px 0 rgb(34 41 47 / 7%);

	ion-list {
		padding: 8px;

		ion-item {
			font-weight: 600;
			font-size: 14px;
			border-radius: 8px;
		}
	}

	ion-content {
		--overflow: hidden !important;
		--background: white !important;
	}

	&::part(arrow) {
		display: none !important;
	}

	ion-footer {
		ion-toolbar {
			--padding-start: 16px !important;
			--padding-end: 16px !important;
			--padding-top: 12px !important;
			--padding-bottom: 12px !important;
		}
	}
}

app-sidebar {
	ion-content::part(scroll) {
		overflow: hidden;
	}
}

input {
	border: 1px solid #dedede;
	padding: 16px;
	background: transparent;
	border-radius: 6px;
	width: 100%;
	-webkit-appearance: none;
	-moz-appearance: none;
	appearance: none;
	outline: none;

	&::-webkit-input-placeholder {
		color: #a1a5b7;
		opacity: 0.9;
	}
}

select {
	border: 1px solid #dedede;
	padding: 13px;
	background: transparent;
	font-weight: 400;
	border-radius: 6px;
	width: 100%;
	-webkit-appearance: none;
	-moz-appearance: none;
	appearance: none;
	cursor: pointer;
	outline: none;
	background-image: url("../assets/icon/down-arrow.svg");
	background-position: calc(100% - 12px) center;
	background-repeat: no-repeat;
	background-size: 18px;
}

select:required:invalid {
	color: rgb(126 126 126 / 81%) !important;
}

option[value=""][disabled] {
	display: none;
}

option {
	color: black;
}

ion-item {
	--inner-padding-end: 0;
	--border-color: #eae9e9;

	ion-thumbnail {
		width: 35px;
		height: 35px;
		min-width: 35px;
		min-height: 35px;
		max-width: 35px;
		max-height: 35px;
		--border-radius: 6px;

		ion-icon {
			font-size: 20px;
		}
	}

	&::part(detail-icon) {
		margin-right: 16px;
		opacity: 1;
		color: var(--ion-color-medium);
		--ionicon-stroke-width: 40px;
		font-size: 17px;
	}

	&[ng-reflect-button='false'] {
		--background-hover: transparent !important;
		--background-activated: transparent !important;
	}

	ion-toggle {
		margin-right: 16px;
	}
}

ion-modal.modal-default:not(.overlay-hidden)~ion-modal.modal-default {
	--backdrop-opacity: 0.4 !important;
}

ion-segment {
	width: unset;
	height: 40px !important;
	--background: var(--ion-color-light);

	ion-segment-button {
		--color-checked: white !important;
		--indicator-color: var(--ion-color-primary) !important;
		font-weight: 600;

		&::part(native) {
			margin: 0 !important;
		}

		ion-label {
			padding-bottom: 0 !important;
			font-weight: 600;
		}
	}
}

// @media (prefers-color-scheme: dark) {

// 	ion-header,
// 	ion-footer {
// 		ion-toolbar {
// 			--background: #242526 !important;
// 			--border-color: var(--ion-color-light);
// 		}
// 	}

// 	ion-input,
// 	ion-textarea,
// 	ion-select {
// 		border: 1px solid #353638;
// 	}

// 	ion-modal {
// 		ion-content {
// 			--background: var(--ion-color-light) !important;
// 		}
// 	}
// }

// body.dark {

// 	ion-header,
// 	ion-footer {
// 		ion-toolbar {
// 			--background: #242526 !important;
// 			--border-color: var(--ion-color-light);
// 		}
// 	}

// 	ion-input,
// 	ion-textarea,
// 	ion-select {
// 		border: 1px solid #3e3f41;
// 	}

// 	ion-modal {
// 		ion-content {
// 			--background: var(--ion-color-light) !important;
// 		}
// 	}
// }