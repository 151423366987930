@font-face {
  font-family: Circular;
  font-weight: 100;
  src: url("./../assets/fonts/CircularStd-Book.otf");
}

@font-face {
  font-family: Circular;
  font-weight: 200;
  src: url("./../assets/fonts/CircularStd-Book.otf");
}

@font-face {
  font-family: Circular;
  font-weight: 300;
  src: url("./../assets/fonts/CircularStd-Book.otf");
}

// NORMAL
@font-face {
  font-family: Circular;
  font-weight: 400;
  src: url("./../assets/fonts/CircularStd-Book.otf");
}

// MEDIUM
@font-face {
  font-family: Circular;
  font-weight: 500;
  src: url("./../assets/fonts/CircularStd-Medium.otf");
}

@font-face {
  font-family: Circular;
  font-weight: 600;
  src: url("./../assets/fonts/CircularStd-Bold.otf");
}

// BOLD
@font-face {
  font-family: Circular;
  font-weight: 700;
  src: url("./../assets/fonts/CircularStd-Black.otf");
}

@font-face {
  font-family: Circular;
  font-weight: 800;
  src: url("./../assets/fonts/CircularStd-Black.otf");
}

@font-face {
  font-family: Circular;
  font-weight: 900;
  src: url("./../assets/fonts/CircularStd-Black.otf");
}
