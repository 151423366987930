@import "~@ionic/angular/css/core.css";
@import "~@ionic/angular/css/normalize.css";
@import "~@ionic/angular/css/structure.css";
@import "~@ionic/angular/css/typography.css";
@import "~@ionic/angular/css/display.css";
@import "~@ionic/angular/css/padding.css";
@import "~@ionic/angular/css/float-elements.css";
@import "~@ionic/angular/css/text-alignment.css";
@import "~@ionic/angular/css/text-transformation.css";
@import "~@ionic/angular/css/flex-utils.css";
@import "styles/dx.light.css";
@import "styles/dx-diagram.css";
@import "~ng-pick-datetime/assets/style/picker.min.css";
@import "./theme/icons.scss";
@import "./theme/responsive.scss";
@import "./theme/variables.scss";
@import "./theme/ionic.scss";
@import "./theme/modals.scss";
@import "./theme/libraries.scss";
@import "./theme/font.scss";

* {
	font-size: 14px;
	font-family: Circular, sans-serif !important;
	letter-spacing: 0.1px;
	font-weight: 400;
	--highlight-background: black;
	-ms-overflow-style: none;
	scrollbar-width: none;

	&::first-letter {
		text-transform: uppercase;
	}

	&::-webkit-scrollbar {
		display: none;
	}
}

h1,
h2,
h3,
h4,
h5,
h6,
p {
	margin: 0;
}

.app {
	&--show {
		display: none;
	}
}

a {
	color: black;
	cursor: pointer;
	text-decoration: underline;
}

hr {
	background: #f4f5f8;
}

.line-status {
	height: 12px;
	width: 12px;
	border-radius: 50px;
	margin-right: 12px;
	margin-top: 2px;

	&.success {
		background: #0380602b;
		border: 2px solid #03806099;
	}

	&.danger {
		background: #ff47592b;
		border: 2px solid #ff475999;
	}
}

.cursor {
	&--pointer {
		cursor: pointer;

		input {
			cursor: pointer;
		}
	}
}

.row-button {
	cursor: pointer !important;
}

.back-btn {
	height: 25px;
	min-width: 25px;
	--padding-start: 0;
	--padding-end: 0;
	--padding-bottom: 0;
	--padding-top: 0;
}

.title {
	margin-bottom: 16px;

	.flex {
		gap: 3px;
	}

	h1 {
		font-weight: 600;
		color: var(--ion-color-dark);
		font-size: 20px;

		ion-text {
			font-size: 20px;
		}
	}

	h2 {
		font-weight: 600;
		color: var(--ion-color-dark);
		font-size: 16px;
	}

	h3 {
		font-weight: 600;
		color: var(--ion-color-dark);
		font-size: 12px;
	}

	p {
		color: #a1a5b7;
		font-size: 14px;
		margin-top: 5px;

		&.font-bg {
			font-size: 15px;
		}
	}

	ion-badge {
		margin-bottom: 4px;
		background: #fcecef !important;
		color: var(--ion-color-danger) !important;
		font-size: 12px;
		font-weight: 600;
		margin: 0;
		margin-left: 5px;
	}

	&--no-bottom {
		margin-bottom: 0%;
	}
}

.flex {
	display: flex;
	flex-direction: row;
	gap: 13px;

	ion-item {
		width: 100%;
	}

	&--gap {
		&-0 {
			gap: 0 !important;
		}

		&-5 {
			gap: 5px !important;
		}

		&-16 {
			gap: 16px !important;
		}
	}
}

.menu-icon {
	padding: 0 10px;
}

.message {
	background-color: #fef1f3;
	padding: 1em 1.5em;
	border-radius: 13px;

	&--header {
		font-size: 16px;
		font-weight: 600;
		color: #CF3C4F;
		display: flex;
		align-items: center;
		gap: 4px;

		ion-icon {
			font-size: 16px;
		}
	}

	p {
		font-size: 14px;
		color: #CF3C4F;
		margin-top: .25em;
	}
}

.selected-list {
	width: 100%;
	padding: 10px;
	border-radius: 0 0 10px 10px;
	border: 1px solid #dedede;
	border-top-width: 0;
	margin-top: -10px;
	padding-right: 0;
	padding-top: 19px;
	padding-bottom: 4px;

	ion-item {
		margin-bottom: 5px;
		width: 100%;

		ion-thumbnail {
			--border-radius: 6px;
			width: 40px;
			height: 40px;
			display: flex;
			align-items: center;
			justify-content: center;

			ion-icon {
				font-size: 27px;
			}
		}

		p {
			font-size: 14px !important;
			font-weight: 700;
			color: var(--ion-color-dark);
		}

		ion-button {
			position: relative !important;
		}
	}
}

.invalid {
	border-radius: 6px;
	border: 1px solid var(--ion-color-danger) !important;
	position: relative;
	z-index: 1111111;
}

.date {
	position: relative;
	width: 100%;

	input {
		padding-left: 43px !important;
	}

	ion-icon {
		position: absolute;
		top: 50%;
		bottom: 50%;
		left: 16px;
		transform: translateY(-50%);
		font-size: 18px;
		color: #292929;
	}
}

.radio-list {
	ion-list-header {
		padding: 0;
		color: black;
		font-weight: 600 !important;
		width: fit-content;
	}

	ion-item {
		margin-top: -10px;

		ion-radio {
			margin-right: 16px;
		}

		ion-label {
			font-weight: 400;
		}
	}
}

.pro-badge {
	font-size: 12px;
	color: var(--ion-color-dark-tint);
	font-weight: bold;
}

.hide-element {
	display: none !important;
}


.hide-code {
	cursor: pointer;

	p,
	ion-icon {
		letter-spacing: 3px;
		font-size: 17px;
	}

	.hide {
		margin-top: 4px !important;
	}

	.code {
		display: none !important;
		font-size: 15px !important;
	}

	&:hover {
		.code {
			display: block !important;

			ion-icon {
				margin-bottom: -2px;
			}
		}

		.hide {
			display: none !important;
		}
	}
}

.wrap {
	text-overflow: ellipsis;
	display: -webkit-box;
	-webkit-box-orient: vertical;
	overflow: hidden;

	&--1 {
		-webkit-line-clamp: 1;
	}

	&--2 {
		-webkit-line-clamp: 2;
	}
}

.auto {
	&--left {
		margin-left: auto !important;
	}
}

.popover-arrow {
	display: none;
}

.infinite-loading-spinner {
	ion-spinner {
		margin-bottom: 16px !important;
	}
}

.w {
	&--100 {
		width: 100% !important;
	}

	&--90 {
		width: 90% !important;
	}

	&--80 {
		width: 80% !important;
	}

	&--70 {
		width: 70% !important;
	}

	&--60 {
		width: 60% !important;
	}

	&--50 {
		width: 50% !important;
	}

	&--40 {
		width: 40% !important;
	}

	&--30 {
		width: 30% !important;
	}

	&--20 {
		width: 20% !important;
	}

	&--10 {
		width: 10% !important;
	}
}

.free-trial-content {
	height: calc(100vh - 60px) !important;
}

.text-image {
	height: 100%;
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
	background: var(--ion-color-light);
	border-radius: 6px;

	h3 {
		font-size: 14px;
		font-weight: 700;
		color: var(--ion-color-medium);
	}
}

.register-input {
	--placeholder-color: #b2b8c5 !important;
	background: white;

	input {
		font-size: 20px !important;
		letter-spacing: 15px !important;
		font-weight: 600 !important;
	}
}

.btn {
	&__white {
		--color: var(--ion-text-color);
		border: 1px solid var(--ion-color-light-shade);
		border-radius: 8px;
	}

	&__remove {
		--background: #fff5f8 !important;
		--background-hover: #fff5f8 !important;
		--background-activated: #fff5f8 !important;
		--color: #f1416c !important;

		ion-icon {
			color: #f1416c !important;
		}
	}

	&__select {
		border: 1px solid #e9eef2;
		color: #a1a5b7;
		width: 100%;
		display: flex;
		border-radius: 6px;
		align-items: center;
		padding: 13px;
		position: relative;
		margin-bottom: 8px !important;
		cursor: pointer;

		span,
		.label-selected {
			display: flex;
			font-size: 14px !important;
		}

		.label-selected {
			color: var(--ion-color-dark) !important;
		}

		.menu-icon {
			font-size: 16px;
			position: absolute;
			right: 0;
			top: 50%;
			bottom: 50%;
			transform: translateY(-50%);
		}

		ion-icon {
			margin-top: 2px;
		}

		.branches {
			display: flex;
			list-style: none;
			padding: 0;
			margin: 0;
			color: black;

			.branch~.branch::before {
				content: ", ";
			}
		}
	}

	&__clear-filter {
		font-size: 16px;
		margin-right: 10px;
		color: #016aff;
	}
}

.search-container {
	width: 100%;
	position: relative;

	ion-list {
		box-shadow: rgba(0, 0, 0, 0.2) 0px 6px 20px !important;
		width: 100%;
		max-height: 250px;
		position: absolute;
		background: white;
		z-index: 11111111111111;
		border-radius: 0 0 10px 10px;
		overflow: auto;
		visibility: hidden;
		transition: 200ms;

		ion-item {
			--padding-start: 16px;
		}
	}

	.searchbar-has-focus+ion-list {
		visibility: initial !important;
	}
}

.edit-item-container {
	.header {
		display: flex;
		flex-direction: column;
		height: 100%;
		background: var(--ion-color-light);
		height: fit-content;
		padding: 16px;
		border-radius: 0 0 20px 20px;

		.save-btn {
			font-size: 17px;
		}
	}

	.product {
		--padding-start: 0;
		--padding-end: 0;
		--background: transparent;

		ion-thumbnail {
			border: 4px solid white;
			width: 50px;
			height: 50px;
			min-width: 50px;
			min-height: 50px;
			max-width: 50px;
			max-height: 50px;
		}

		ul {
			list-style: none;
			padding: 0;
			margin: 0;
			color: var(--ion-color-medium);

			li {
				font-size: 14px !important;
				margin-top: 4px;

				p,
				span,
				div {
					font-size: 14px !important;
				}
			}
		}
	}
}

.search-container {
	width: 100%;
	position: relative;

	ion-list {
		box-shadow: rgba(0, 0, 0, 0.2) 0px 6px 20px !important;
		width: 100%;
		max-height: 250px;
		position: absolute;
		background: white;
		z-index: 11111111111111;
		border-radius: 0 0 10px 10px;
		overflow: auto;

		ion-item {
			--padding-start: 16px;
		}
	}
}

.menu-table {
	padding: 16px;
	background: transparent;

	ion-searchbar {
		height: 39.19px !important;
	}

	ion-button {
		width: fit-content !important;
		margin: 0;

		.btn-icon {
			margin-right: 10px;
		}
	}

	ion-select {
		background: var(--ion-color-light) !important;
		border: 0;
		width: 100%;
	}

	h1 {
		font-weight: 600;
		font-size: 20px;
	}

	.title {
		margin-bottom: 0 !important;
	}
}

.title-page {
	margin-left: 10px;
	margin-right: 10px;
}

.card-resume {
	width: 185px;
	height: 190px;

	ion-card-content {
		padding: 16px !important;
		display: flex;
		flex-direction: column;
		height: 100%;
	}

	.info {
		text-align: left;
	}

	h1 {
		font-weight: 600;
		color: black;
		margin-top: 1.75rem !important;
		font-size: 20px;
	}

	p {
		color: var(--ion-color-medium);
		// margin-bottom: 1.75rem !important;
	}

	ion-icon {
		width: 3rem !important;
		height: 3rem !important;
		font-size: 3rem;
		color: #7e8299;
		flex-grow: 1;
	}
}

.font {
	&--bold {
		font-weight: 500 !important;
	}

	&--bolder {
		font-weight: bold !important;
	}
}

.thread {
	&--1 {
		position: absolute;
		left: 18px;
		width: 24px;
		border-bottom: 2px solid #f1f5f8;
	}

	&--2 {
		position: absolute;
		height: 100%;
		width: 2px;
		left: 18px;
		background: #f1f5f8;
		z-index: 111111;
	}

	&--last {
		top: 0;
		height: 50%;
	}
}

.card-info {
	margin: 0;
	width: 100%;

	ion-card-content {
		padding: 1rem !important;
	}

	.icon {
		background: #f1faff;
		width: fit-content;
		display: flex;
		align-items: center;
		justify-content: center;
		width: 60px;
		height: 60px;
		border-radius: 6px;

		ion-icon {
			color: var(--ion-color-primary);
			font-size: 30px;
		}
	}

	.flex {
		gap: 16px;
	}

	h1 {
		font-weight: 700;
		font-size: 18px;
		color: var(--ion-color-dark);
	}

	p {
		color: var(--ion-color-medium);
		font-size: 13px;
	}
}

.money {
	display: flex;
	margin-bottom: -10px;

	.symbol {
		color: #b5b5c3;
		font-weight: 600 !important;
		font-size: 1.25rem !important;
		margin-top: 7px;
		margin-right: 3px;
	}

	.amount {
		font-weight: 600 !important;
		font-size: 2.5rem !important;
		color: #3f4254;
	}

	p {
		color: var(--ion-color-medium);
	}
}

.toast-button {
	padding-right: 0 !important;
}

.success {
	&::part(button) {
		color: var(--ion-color-success) !important;
	}
}

.error {
	&::part(button) {
		color: var(--ion-color-danger) !important;
	}
}

.quanto-loader {
	--backdrop-opacity: 0.1 !important;

	.loading-wrapper {
		flex-direction: column;
		background-color: rgb(255 255 255 / 75%);
		backdrop-filter: blur(4px) !important;
		min-width: 100%;
		min-height: 100%;
		border-radius: 0;
	}

	.loading-spinner {
		height: 100px;
		margin-bottom: 16px;

		ion-spinner {
			margin: 0 !important;
			width: 80px !important;
			color: var(--ion-color-primary) !important;
			height: 80px;
			position: relative !important;
			top: 0 !important;
			bottom: 0 !important;
			transform: translateY(0) !important;
		}
	}

	.loading-content {
		font-weight: 600;
		color: black;
		text-align: center;
		font-size: 35px;
		margin-inline-start: 0 !important;
		margin-top: 8px;

		&:after {
			content: "No cierres esta pagina.";
			display: block;
			margin-top: 8px;
			font-weight: 300;
			font-size: 18px;
			color: var(--ion-color-dark-tint);
		}
	}

	&.dark {
		.loading-wrapper {
			background-color: rgb(22 27 38 / 75%) !important;
		}

		.loading-content {
			color: white !important;

			&:after {
				color: var(--ion-color-light) !important;
			}
		}
	}
}

.required:after {
	content: "*";
	position: relative;
	font-size: inherit;
	color: #f1416c;
	font-weight: 700;
}

.checkbox-input {
	align-items: flex-start;

	h6 {
		font-size: 14px;
		font-weight: 600;
	}

	.description {
		margin-top: 5px;
		color: var(--ion-color-medium);
	}
}

.title-page {
	margin-left: 16px;
	margin-right: 16px;
}

.show-arrow {
	.ion-accordion-toggle-icon {
		display: block !important;
		margin-right: 16px;
		position: absolute;
		right: 0;
	}
}

.table-container {
	overflow: initial;

	ion-card-header {
		border-bottom: 0.1px solid var(--ion-color-light);
		padding: 16px;
		display: flex;
		flex-direction: row;
		gap: 16px;

		ion-searchbar {
			width: 100%;
			min-height: 45px;
		}

		ion-segment {
			--background: var(--ion-color-light);

			ion-label {
				margin: 0 30px;
			}
		}
	}

	ion-card-content {
		padding: 0 !important;
	}

	ion-spinner {
		position: relative;
		margin: 32px 0;
	}

	.header {
		padding: 0;
		background: var(--ion-color-light-tint);
		border-bottom: 0.1px solid var(--ion-color-light);
		position: sticky;
		top: 0;
		z-index: 1111;

		&--light {
			border-radius: 13px 13px 0 0;
			background: white !important;
		}

		ion-col {
			padding: 12px 16px;
			font-size: 12px;
			color: var(--ion-color-medium);
			font-weight: 600;
			text-transform: uppercase;
		}
	}

	.body {
		padding: 0;

		ion-col {
			padding: 12px 16px;
			display: flex;
			align-items: center;
			min-height: 70px;
			line-height: 1.65;
			color: var(--ion-color-dark);

			p {
				margin: 0;
				color: var(--ion-color-dark);
			}

			ion-note {
				font-size: 14px !important;
			}

			ion-button {
				height: 40px;
				min-height: 40px;

				&.button-has-icon-only ion-icon {
					font-size: 18px;
				}
			}

			.button-has-icon-only {
				--padding-end: 0px;
				--padding-start: 0px;
				width: 40px;
				min-width: 40px;
			}

			ion-item {
				--background-activated: transparent;
				--background-hover: transparent !important;
			}
		}
	}

	ion-item {
		--padding-start: 0;
		--padding-end: 0;
		--background: transparent;
		margin-left: 0;
		--border-width: 0 !important;
	}
}

.card-content-ios p {
	font-size: 14px;
}

.old-table-container {
	background: white;
	margin: 0;
	border-radius: 12px;
	box-shadow: 0 4px 24px 0 rgb(34 41 47 / 5%);
	height: fit-content;

	.table-header {
		position: sticky;
		top: -16px;
		background: var(--ion-color-light-tint);
		z-index: 1111;
		width: 100%;
		border-top: 1px solid #eff3f6;
		border-bottom: 1px solid #eff3f6;
		padding: 0;

		ion-col {
			font-weight: 600;
			color: #6e6b7b;
			text-transform: capitalize;
			white-space: nowrap;
			text-overflow: ellipsis;
			overflow: hidden;
			padding: 12px 16px;

			ion-checkbox {
				margin-right: 10px;
			}
		}
	}

	ion-spinner {
		width: 100%;
		margin: 32px 0;
		position: relative !important;
		top: 0 !important;
		bottom: 0 !important;
		transform: translateY(0) !important;
	}

	.table-body {
		.button-has-icon-only {
			--padding-end: 0px;
			--padding-start: 0px;
			width: 39px;
			height: 39px;
			min-width: 39px;
			min-height: 39px;
		}

		ion-item {
			--padding-start: 0;
			--padding-end: 0;
			--background: transparent;

			&::part(detail-icon) {
				margin-right: 16px;
			}
		}

		ion-grid {
			padding: 0;
			border-bottom: 1px solid var(--ion-color-light);

			ion-col {
				display: flex;
				align-items: center;
				gap: 10px;
				min-height: 70px;
				padding: 8px 16px;
				line-height: 20px;
				font-size: 15px;

				ion-icon {
					font-size: 18px;
				}

				ion-text,
				span {
					font-size: 15px;
				}

				p {
					width: 100%;
					white-space: nowrap;
					text-overflow: ellipsis;
					overflow: hidden;
					font-size: 15px;
				}

				ion-note {
					margin-top: 4px;
					font-size: 14px;
				}

				ion-item {
					--background: transparent;
					--background-activated: transparent;
					--background-hover: transparent;
				}

				ion-thumbnail {
					--border-radius: 6px;
					width: 40px;
					height: 40px;
					display: flex;
					align-items: center;
					justify-content: center;
				}

				h3 {
					font-size: 14px;
					font-weight: 600;
				}

				ion-button {
					--background: var(--ion-color-light);
					--background-hover: var(--ion-color-light);
					--background-activated: var(--ion-color-light);
					--color: var(--ion-color-medium);
					--padding-start: 13px;
					--padding-end: 13px;
					--padding-top: 13px;
					--padding-bottom: 13px;
					height: 39px;

					ion-icon {
						font-size: 17px;
						color: #7e8299;
					}
				}
			}
		}

		a {
			display: flex;
			align-items: center;
			gap: 5px;
		}
	}
}

.menu-header::part(content) {
	--width: 290px;
	transform: translateX(-20px);
}

.color {
	&--primary {
		color: var(--ion-color-primary) !important;
	}

	&--medium {
		color: var(--ion-color-medium) !important;
	}

	&--light {
		color: var(--ion-color-light) !important;
	}

	&--dark {
		color: var(--ion-color-dark) !important;
	}

	&--success {
		color: #25af60 !important;
	}

	&--danger {
		color: var(--ion-color-danger) !important;
	}
}

.empty-data {
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: column;
	text-align: center;
	max-width: 425px;
	margin: auto;
	padding: 5vw 0;

	img,
	ion-img {
		margin-bottom: 25px;
		width: 170px;
	}

	h1 {
		font-weight: 600;
		font-size: 24px;
		color: var(--ion-color-dark);
	}

	p {
		margin-top: 13px;
		color: #6a7383;
		font-size: 14px;
		line-height: 1.5;
	}

	ion-button {
		margin-top: 25px;
	}
}

.radio-group-container {
	display: flex;
	gap: 10px;
	width: 100%;

	ion-radio,
	ion-checkbox {
		--color: #dedfe1;
		margin-left: 16px;
		width: 20px !important;
	}

	ion-item {
		width: 50%;
		margin-right: 0 !important;
		border-width: 1px;
		border-style: solid;
		color: #7e8299;
		border-color: #e4e6ef;
		--padding-start: 0px !important;
		--padding-end: 16px !important;
		--padding-top: 5px !important;
		--padding-bottom: 5px !important;
		transition: all 0.1s ease-out;
		border-radius: 8px !important;
		--border-radius: 8px !important;

		ion-label {
			margin: 0 !important;
			color: var(--ion-color-dark) !important;
			font-size: 14px !important;
			font-weight: 600;
			padding: 0 !important;
		}

		&:hover {
			color: var(--ion-color-primary);
			border-color: var(--ion-color-primary);
			--background: #f1faff !important;
		}
	}

	.checked {
		color: var(--ion-color-primary);
		border-color: var(--ion-color-primary);
		--background: #f1faff !important;
	}
}

.valid-lbl {
	color: var(--ion-color-danger) !important;
}

.form,
form {
	ion-item {
		--inner-padding-end: 0;
		--padding-end: 0;
		--padding-start: 0 !important;
		--background: white;
		--background-focused: white;
		--background-activated: white;
		--background-hover: transparent;

		p {
			color: #a1a5b7;
			font-size: 12px !important;
			margin-top: 5px;
		}

		ion-button {
			// position: absolute;
			// right: 8px;
			// top: 24px;
			// height: 50px;
			// z-index: 1111111;

			ion-icon {
				--ionicon-stroke-width: 40px;
				font-size: 20px !important;
			}
		}

		ion-note {
			display: none;
			color: var(--ion-color-danger);
			margin-top: 8px;
			font-size: 12px;
		}

		ion-input {
			ion-button {
				position: absolute;
				right: 8px;
				bottom: 2px;
				z-index: 1111111;
			}
		}
	}

	.input-note {
		color: #a1a5b7 !important;
		font-size: 12px !important;
		margin-top: 5px;
	}
}

.tooltip {
	--width: fit-content;
	--background: transparent;
	margin-left: 10px;

	&::part(content) {
		box-shadow: none !important;
	}

	&::part(backdrop) {
		display: none;
	}
}

.color-picker {
	--width: auto;
	position: relative !important;
	top: 0 !important;
	left: 0 !important;
	border: none !important;

	button {
		font-size: 14px !important;
		padding: 13px !important;
		width: 100% !important;
		margin: 0 !important;
		border-radius: 6px !important;
		background: var(--ion-color-secondary) !important;
		font-weight: 600 !important;
		color: white !important;
	}
}

.item-disabled {
	opacity: 0.5 !important;
}

.empty-slot {
	width: 100%;
	padding: 16px 20px;
	text-align: center;
	border: 1px solid rgb(35 107 254 / 47%);
	color: #236bfe;
	background: #e8f2ff;
	border-radius: 6px;
	cursor: pointer;

	span {
		font-weight: 600;
	}
}

.date-input {
	background: white;
	height: 36px;
	min-height: 36px;
	border-radius: 6px;
	display: flex;
	align-items: center;
	position: relative;

	input {
		width: 200px;
		text-align: center;
		height: 36px;
		min-height: 36px;
		border: none;
		background: white;
		outline: none;
		font-weight: 600;
		cursor: pointer;
		padding: 10px !important;
		border-top: 1px solid var(--ion-color-light-shade);
		border-bottom: 1px solid var(--ion-color-light-shade);
		border-radius: 0;

		&::placeholder {
			color: var(--ion-color-dark) !important;
			opacity: 1 !important;
		}
	}

	ion-button {
		--background: white;
		--background-hover: #eaeef4;
		--background-activated: #eaeef4;
		--color: var(--ion-color-dark);
		margin: 0 !important;
		border: 1px solid var(--ion-color-light-shade);
		height: 36px;
		width: 36px;
		min-width: 36px;
	}

	.back {
		--border-radius: 6px 0px 0px 6px;
		border-radius: 6px 0px 0px 6px;
	}

	.forward {
		--border-radius: 0 6px 6px 0;
		border-radius: 0 6px 6px 0;
	}
}

.auth-header {
	z-index: 1111;
	width: 100%;
	z-index: 1111;
	width: 100%;
	background: white;

	.logo {
		cursor: pointer;
		width: 30%;

		img {
			width: 120px;
		}
	}

	ion-progress-bar {
		width: 40%;
		height: 10px;
		border-radius: 10px;
	}

	ion-item {
		width: 30% !important;
		text-align: right;
		cursor: pointer;

		ion-label {
			padding: 0;
		}
	}

	.button-has-icon-only {
		ion-icon {
			font-size: 20px !important;
			color: #8289a5;
		}
	}
}

.dp-none {
	visibility: hidden;
}

.select-interface-option {
	--background-focused: white;

	.radio-checked {

		// display: none;
		&::part(mark) {
			display: none !important;
		}
	}

	&.item-radio-checked {
		--background: #ecf3ff;
		color: var(--ion-color-primary);
		--color: var(--ion-color-primary);
	}
}

.h {
	&--100 {
		height: 100% !important;
	}
}

.input-small {
	input {
		font-size: 18px !important;
	}
}

.quantity-input {
	display: flex;
	gap: 10px;
	border-radius: 10px;
	padding: 10px;
	background: #fff;

	ion-button {
		height: 65px;
		width: 150px;
		margin: 0;
		--background: var(--ion-color-light);
		--background-activated: #fbfbfb;
		--background-hover: #fbfbfb;
		--color: var(--ion-color-dark);

		ion-icon {
			font-size: 25px;
			--ionicon-stroke-width: 50px;
		}
	}

	ion-input {
		text-align: center;

		input {
			height: 55px !important;
			font-weight: 800 !important;
			border: none !important;
			color: #2c3e4d !important;
			font-size: 35px !important;
			padding: 0 !important;
		}
	}
}


.input {
	&--phone {
		input {
			padding-left: 70px !important;
		}

		ion-button {
			left: 0 !important;
			width: 70px;

			img {
				width: 25px;
				border-radius: 3px;
				margin-right: 10px;
			}

			ion-icon {
				margin-left: 0;
			}
		}
	}

	&--card {

		img {
			width: 35px;
			margin-right: 10px;
			position: absolute;
			bottom: 0;
			left: 8px;
			bottom: 6px;
		}

		ion-icon {
			position: absolute;
			left: 8px;
			font-size: 27px;
			bottom: 9px;
		}

		input {
			letter-spacing: 4px !important;
			padding-left: 50px !important;
		}
	}

	&--icon {
		ion-icon {
			position: absolute;
			left: 14px;
			font-size: 16px;
			bottom: 15px;
		}

		input {
			padding-left: 45px !important;
		}
	}
}

.numeric-panel {
	display: flex;
	flex-direction: column;
	width: 100% !important;
	height: 100%;

	.input {
		display: flex;
		color: var(--ion-color-dark);
		width: 100%;
		font-size: 50px;
		font-weight: 700;
		align-items: center;
		justify-content: center;
		padding: 34px 0;
		border-bottom: 0.5px solid var(--ion-color-light-shade);

		span {
			font-size: 20px !important;
			font-weight: 600;
			margin-left: 10px;
			color: var(--ion-color-medium);
		}
	}

	.numbers {
		display: grid;
		grid-template-columns: 1fr 1fr 1fr;
		height: 100%;


		ion-button {
			border: 0.5px solid var(--ion-color-light-shade);
			font-size: 25px;
			font-weight: 600;
			width: 100%;
			height: 100%;
			margin: 0;
			color: black;
			--background: #fff;
			--background-hover: var(--ion-color-light);
			--background-activated: var(--ion-color-light);
			--border-radius: 0%;

			ion-icon {
				font-size: 40px;
			}
		}

		.button-disabled {
			--background: white;
			--background-hover: transparent;
			--background-activated: transparent;
			color: transparent;
			opacity: 1;
		}
	}

	.btn-green {
		color: var(--ion-color-success) !important;
	}

	.options {
		display: flex;
		flex-direction: column;

		ion-button {
			color: var(--ion-color-primary);
		}

		&-left {
			width: 150px;
		}

		&-right {
			width: 200px;
		}
	}

	ion-icon {
		--ionicon-stroke-width: 80px;
	}
}

.radio-group-container {
	display: flex;
	gap: 10px;
	width: 100%;

	ion-radio,
	ion-checkbox {
		--color: #dedfe1;
		margin-left: 16px;
	}

	ion-item {
		width: 50%;
		margin-right: 0 !important;
		border-width: 1px;
		border-style: solid;
		color: #7e8299;
		border-color: #e4e6ef;
		border-radius: 6px;
		--padding-start: 0px !important;
		--padding-end: 16px !important;
		--padding-top: 5px !important;
		--padding-bottom: 5px !important;
		transition: all 0.1s ease-out;

		ion-label {
			margin: 0 !important;
			color: var(--ion-color-dark) !important;
			font-size: 14px !important;
			font-weight: 600;
			padding: 0 !important;
		}

		&:hover {
			color: var(--ion-color-primary);
			border-color: var(--ion-color-primary);
			--background: #f1faff !important;
		}
	}

	.checked {
		color: var(--ion-color-primary);
		border-color: var(--ion-color-primary);
		--background: #f1faff !important;
	}
}

a {
	text-decoration: none;
}

.vertical-accordion {
	width: 100%;
	display: flex;
	gap: 0;
	height: 100%;

	ion-accordion {
		overflow: auto;
		border-right: 1px solid #e6edf5;

		&.accordion-collapsed {
			height: 100%;
			background: var(--ion-color-light);
			width: 60px;
			max-width: 60px;
			min-width: 60px;

			div[slot="header"] {
				cursor: pointer;

				p {
					writing-mode: vertical-lr;
					font-size: 15px !important;
					font-weight: 600;
					width: 100%;
					display: flex;
					align-items: center;
					padding-top: 16px;

					ion-text {
						display: contents !important;
						font-size: 15px !important;
						color: var(--ion-color-primary);
						font-weight: 600;
					}
				}
			}
		}

		&.accordion-expanded {
			div[slot="header"] {
				display: none;
			}

			div[slot="content"] {
				display: flex;
				flex-direction: column;
				height: 100%;
				width: 100%;
				position: absolute;

				.title {
					padding: 20px;
					padding-bottom: 10px;
					margin-bottom: 0;
					cursor: default !important;
				}

				ion-footer {
					position: fixed;
					bottom: 0;
					right: -1px;
					width: -webkit-stretch;

					ion-button {
						font-size: 16px;
					}
				}
			}
		}
	}
}

ion-icon[class*="category-"] {
	-webkit-mask-position: center;
	mask-position: center;
}

// Creates a ribbon effect with the given options:
@mixin ribbonize($ribbon-color, $text-color: white, $border-width: 3px) {
	span {
		color: $text-color;
		background: $ribbon-color;

		&::before {
			border-left: $border-width solid $ribbon-color;
			border-top: $border-width solid $ribbon-color;
		}

		&::after {
			border-right: $border-width solid $ribbon-color;
			border-top: $border-width solid $ribbon-color;
		}
	}
}

.item-ribbon {
	background-color: var(--ion-color-dark-tint);

	&.light {
		background-color: var(--ion-color-light-tint);
	}

	position: relative;
	filter: none;
	opacity: 1;
}

.ribbon {
	position: absolute;
	right: -5px;
	top: -5px;
	z-index: 100;
	overflow: hidden;
	width: 75px;
	height: 75px;
	text-align: right;

	span {
		font-size: 1rem;
		font-weight: 600;
		color: #fff;
		text-transform: uppercase;
		text-align: center;
		line-height: 20px;
		transform: rotate(45deg);
		-webkit-transform: rotate(45deg);
		width: 100px;
		display: block;
		background: var(--ion-color-warning);
		position: absolute;
		top: 19px;
		right: -21px;

		&::before {
			content: "";
			position: absolute;
			left: 0;
			top: 100%;
			z-index: -1;
			border-left: 3px solid var(--ion-color-warning);
			border-right: 3px solid transparent;
			border-bottom: 3px solid transparent;
			border-top: 3px solid var(--ion-color-warning);
		}

		&::after {
			content: "";
			position: absolute;
			right: 0;
			top: 100%;
			z-index: -1;
			border-left: 3px solid transparent;
			border-right: 3px solid var(--ion-color-warning);
			border-bottom: 3px solid transparent;
			border-top: 3px solid var(--ion-color-warning);
		}
	}
}

.ribbon.cruzeiro {
	@include ribbonize(var(--ion-color-warning));
}

.tracking-css {
	align-items: center;
	justify-content: center;
	--min-height: 50%;
	--min-width: 90%;
	--max-height: 50%;
	--max-width: 90%;
	--border-radius: 20px;
}